import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Espectacular bolet en forma de corall de 5 a 8 cm d’altura, que neix d’un peu comú i que després es va ramificant amb branques dretes atapeïdes unes contra les altres i acabades amb punta. Branques de color groc ocraci, amb les puntes de color més viu, amb reflexos color carn o vinós cap al peu. Les espores són el·líptiques de color ocre, finament berrugoses, de 8-10 x 4-5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      